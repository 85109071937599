import { defineComponent, reactive, ref, useCssModule } from '@nuxtjs/composition-api';
import { parseAdfoxHTML } from '~/utils';
export default defineComponent({
    name: 'FullscreenAdfox',
    props: {
        config: {
            required: true,
            type: Object,
        },
    },
    setup() {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const rootEl = ref();
        const adfoxArticle = ref();
        const isVisible = ref(false);
        const countdown = reactive({
            id: 0,
            isCompleted: false,
            isEnabled: true,
            step: 1000,
            tick: 8,
        });
        function close() {
            isVisible.value = false;
        }
        function onComplete({ state }) {
            if (state.withError || state.withStub || !rootEl.value) {
                return;
            }
            const adfoxEl = rootEl.value.querySelector('[id^="adfox"]');
            if (!adfoxEl) {
                return;
            }
            // Parse HTMl in Adfox container
            adfoxArticle.value = parseAdfoxHTML(adfoxEl);
            isVisible.value = adfoxArticle.value.type !== 'unknown' && Object.keys(adfoxArticle.value).length > 1;
            if (isVisible.value) {
                startCountdown();
            }
        }
        function startCountdown() {
            if (!countdown.isEnabled) {
                countdown.isCompleted = true;
                return close();
            }
            const start = Date.now();
            let time = 0;
            function tick() {
                time += countdown.step;
                countdown.tick--;
                if (countdown.tick === 0) {
                    countdown.isCompleted = true;
                    window.clearTimeout(countdown.id);
                    close();
                }
                else {
                    const diff = Date.now() - start - time;
                    countdown.id = window.setTimeout(tick, countdown.step - diff);
                }
            }
            countdown.id = window.setTimeout(tick, countdown.step);
        }
        return {
            adfoxArticle,
            close,
            countdown,
            css,
            isVisible,
            onComplete,
            rootEl,
        };
    },
});
